import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { stateAreaSelectProduct } from '../../../Redux/Actions/Area';


function MoreInfoColorSchemes(props){    
    const { product, products, selectProduct } = props

    const [scheme, setScheme] = useState(0);
    
    var fproducts = products.filter((item)=>{
        switch(scheme){
            case 5: return (product.id!==item.id && product.related.tetradic.indexOf(item.id)>-1)
            case 4: return (product.id!==item.id && product.related.triadic.indexOf(item.id)>-1)
            case 3: return (product.id!==item.id && product.related.analogous.indexOf(item.id)>-1)
            case 2: return (product.id!==item.id && product.related.comp.indexOf(item.id)>-1)
            case 1: return (product.id!==item.id && product.related.slitcomp.indexOf(item.id)>-1)
            default: return (product.id!==item.id && product.related.monochrom.indexOf(item.id)>-1)
        }
        
    })

    //const wd = Math.floor(100/fproducts.length);

    return (
        <div className="moreinfoin">
            <div className="schemeslinks">
            <Link className={"moreinfo" + (scheme===0?' active':'') } to="#" onClick={(e)=>{ e.preventDefault(); setScheme(0); }}>Monochromatic</Link>
            <Link className={"moreinfo" + (scheme===1?' active':'') } to="#" onClick={(e)=>{ e.preventDefault(); setScheme(1); }}>Split Complementary</Link>
            <Link className={"moreinfo" + (scheme===2?' active':'') } to="#" onClick={(e)=>{ e.preventDefault(); setScheme(2); }}>Complementary</Link>
            </div>
            <div className="schemeslinks">
            <Link className={"moreinfo" + (scheme===3?' active':'') } to="#" onClick={(e)=>{ e.preventDefault(); setScheme(3); }}>Analogous</Link>
            <Link className={"moreinfo" + (scheme===4?' active':'') } to="#" onClick={(e)=>{ e.preventDefault(); setScheme(4); }}>Triadic</Link>
            <Link className={"moreinfo" + (scheme===5?' active':'') } to="#" onClick={(e)=>{ e.preventDefault(); setScheme(5); }}>Tetradic</Link>
            </div>

            <div className="schemescolor">
                <div style={{width:'100%', backgroundColor:'#'+product.color}}></div>
                {fproducts && fproducts.map((fproduct, inx)=>{                
                return (<div key={"schemecolor"+inx} className="schemesref" style={{ backgroundColor:'#'+fproduct.color }} onClick={()=>{
                        selectProduct(fproduct)
                    }}>                        
                </div>)
            })}
            </div>

            
        </div>
    )
}


const mapStateToProps = state => {
    return {
        products: state.application.products,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectProduct: (product) => dispatch(stateAreaSelectProduct(product)),
    }
}
  
  
export default connect(mapStateToProps, mapDispatchToProps)(MoreInfoColorSchemes);