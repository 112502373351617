import React, { Component } from 'react';
import './itemerrors.css'
class ItemErrors extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    renderError() {
        var className = this.props.info ? 'infoText' : 'errorText';
        if (this.props.info) {
            return (
                <div className={className}>{this.props.errorData}</div>
            );
        } else {
            return(
                Object.keys(this.props.errorData).map((key, fld) => {
                    return (
                        <div key={key} className={className}>{this.props.errorData[key]}</div>
                    );
                })
            )           
        }
    }
    render() {
        return (
            <div className="errorBox">
                {this.renderError()}
            </div>
        );
    }
}

export default ItemErrors;