import React from 'react';
import { connect } from 'react-redux'
import { Group } from 'react-konva'
import URLImage from '../../../Ui/Konvajs/URLImage';
import { stateAreaSelect, stateAreaHoverIn, stateAreaHoverOut } from '../../../Redux/Actions/Area'



class CanvasAreaHover extends React.Component  {    

    constructor(props) {
        super(props);
     
        this.hover = false
        this.hoverimage = null
        
        this.handleSelect = this.handleSelect.bind(this)
        this.handleHoverLoad = this.handleHoverLoad.bind(this)
    }

    componentWillReceiveProps(nextProps){ 
        if(this.hoverimage==null) return;
        if(!this.hover && nextProps.areahover===this.props.areakey){
            this.hoverimage.opacity(0.8).drawHitFromCache();
            this.hoverimage.getLayer().batchDraw()
            this.hover=true
        }
        if(this.hover && nextProps.areahover!==this.props.areakey){
            this.hoverimage.opacity(0).drawHitFromCache();
            this.hoverimage.getLayer().batchDraw()
            this.hover=false
        }
    }
    
    shouldComponentUpdate(nextProps, nextState) {        
        return false
    }

    handleSelect(){
        this.props.stateAreaSelect(this.props.areakey)
    }

    handleHoverLoad(image){
        this.hoverimage = image
        this.hoverimage.cache();
        this.hoverimage.getLayer().batchDraw()
    }
    

    render(){
        const { area, areakey, path } = this.props
        const url = path + "day/"+ area.image

        return (
            <Group name={"hover_"+areakey}>
                <URLImage ref="hoverimage" src={url} x={area.offset.x} y={area.offset.y} opacity={0} filters={[this.filterHover]}
                onLoad={this.handleHoverLoad} onMouseEnter={()=>{
                    this.props.stateAreaHoverIn(this.props.areakey)
                }} onMouseLeave={()=>{
                    this.props.stateAreaHoverOut(this.props.areakey)
                }} onTouchEnd={this.handleSelect} onMouseUp={this.handleSelect}
                 />
            </Group>
        )
    }

    filterHover(imageData){        
        var nPixels = imageData.data.length
        for (var i = 0; i < nPixels; i += 4) {
            if(imageData.data[i+3]>0){
                imageData.data[i]=255;
                imageData.data[i+1]=100;
                imageData.data[i+2]=100;
            }
        }
    }
      
}


const mapStateToProps = state => {
    return {
        areahover: state.area.area_hover
    }
}

const mapDispatchToProps = dispatch => {
  return {
    stateAreaSelect: (area) => dispatch(stateAreaSelect(area)),
    stateAreaHoverIn: (area) => dispatch(stateAreaHoverIn(area)),
    stateAreaHoverOut: (area) => dispatch(stateAreaHoverOut(area)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CanvasAreaHover)