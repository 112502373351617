import React from 'react'
import { connect } from "react-redux"
import { Link } from "react-router-dom";

import { stateCartUpdateItems } from "../../../Redux/Actions/Cart"


const VisualizerCartItems = ({cart, products, updateCart}) =>{
    if(!products) return null;

    if(!cart || cart.length<=0){
        return(<p>No items added to cart</p>)
    }
    
    const fproduct = products.filter((item)=> cart.indexOf(item.id)>-1)
        
    if(fproduct.length<=0){
        return(<p>No items added to cart</p>)
    }

    const handleRemove=(productId)=>{
        cart = cart.filter(pid => pid !== productId);        
        updateCart(cart)
    }

    return (
        <div className="cartitems">
            <ul className="list">
                {fproduct && fproduct.map((product, inx)=>{
                    return (<CartItem key={"cartitem"+inx} product={product} onRemove={handleRemove} />)
                })}
            </ul>
        </div>
    )

}

const CartItem = ({product, onRemove})=>{

    var style={}
    style['backgroundColor'] = '#'+product.color
    if(product.type==='Texture'){
        style['backgroundImage'] = 'url('+product.image+')'
    }

    return (<li className="cartitem clear">
        <span className="color" style={style}></span>
        <Link className="action" to={loc=>{ return null }} onClick={(e)=>{
            e.stopPropagation()
            e.preventDefault()
            onRemove(product.id)
        }} >Remove</Link>     
        <div className="detail">
            <h4>{product.name}</h4>
            <span>Code: {product.code}</span>
        </div>
    </li>)
}



const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
        products: state.application.products,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateCart: (cart) => dispatch(stateCartUpdateItems(cart)),
    }
}  

export default connect(mapStateToProps, mapDispatchToProps)(VisualizerCartItems)