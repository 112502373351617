import { combineReducers } from 'redux'

import application from './Application'
import area from './Area'
import cart from './Cart'
import template from './Template'
 
const rootReducer = combineReducers({
    application, 
    area,
    cart,
    template,
});
 
export default rootReducer;