import { createStore, applyMiddleware } from 'redux';
import rootReducer from './Reducers';

import templateMiddleware from './Middleware/Template';

let store = createStore(
    rootReducer,
    applyMiddleware(templateMiddleware)
);
 
export default store;