import React, { Component } from 'react'

import { getTemplateCategory } from '../../Model/TemplateCategory'
import Template from './template'
import './scene.css'
import ChangeSceneTopBar from './changescene.topbar'
class Scene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loading: false,
      type: "Interior",
    };
    this.loadTemplateCategory = this.loadTemplateCategory.bind(this)

  }

  componentDidMount() {
    this.loadTemplateCategory(this.state.type);
  }


  loadTemplateCategory(type) {
    this.setState({ loading: true })
    var optdata = { data: type }
    getTemplateCategory(optdata, (res) => {
      this.setState({ items: res.data, loading: false });
    }, (res) => {
      this.setState({ loading: false })
    });
  }


  renderCategory(item) {

    return (
      <div key={"scene" + item.id} className="catgroup">
        {/*<h1>{item.type_name}</h1>*/}
        <Template data={item.template} />
      </div>
    )

  }

  changeType(type) {
    this.setState({ type: type })
    this.loadTemplateCategory(type)
  }


  render() {
    const { items } = this.state
    return (
      <div className="scene-content">
        <ChangeSceneTopBar />
        <div className="wrapping">
          <div className="scene-heading">
            <h2>Sample Scenes</h2>
            <h4>Use one of our images to start painting</h4>
          </div>

          <div className="tabbing">
            <ul>
              <li className={this.state.type === 'Interior' ? 'active' : ''} onClick={() => { this.changeType('Interior') }}>Interior</li>
              <li className={this.state.type === 'Exterior' ? 'active' : ''} onClick={() => { this.changeType('Exterior') }}>Exterior</li>
            </ul>
          </div>
          <div className="template-list">
            {
              this.state.loading &&
              <h4>{'Loading.....'}</h4>}
            {!this.state.loading &&
              items.map(item => (
                this.renderCategory(item)
              ))
            }
          </div>
        </div>
      </div>
    );
  }

}
export default Scene;
