import React from 'react'
import { connect } from 'react-redux'
import { stateAreaSelectProduct } from '../../../Redux/Actions/Area';


function MoreInfoReleated(props){    
    const { product, products, selectProduct } = props

    
    var fproducts = products.filter((item)=>{
        return (product.id!==item.id && product.related.related.indexOf(item.id)>-1)
    })

    const wd = Math.floor(100/fproducts.length);

    return (
        <div className="moreinfoin">
            {fproducts && fproducts.map((fproduct, inx)=>{                
                return (<div key={"relcolor"+inx} className="relatedproduct" style={{flost:'left', width:wd+'%' }}>
                    <div style={{backgroundColor:'#'+fproduct.color}} onClick={()=>{
                        selectProduct(fproduct)
                    }}></div>
                </div>)
            })}
        </div>
    )
}


const mapStateToProps = state => {
    return {
        products: state.application.products,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectProduct: (product) => dispatch(stateAreaSelectProduct(product)),
    }
}
  
  
export default connect(mapStateToProps, mapDispatchToProps)(MoreInfoReleated);