import React, { Component } from 'react'
import Logo from '../../images/logo.png'
import { Link } from "react-router-dom";
import DyrupLogo from '../../images/dyrup_logo.png'

class ChangeSceneTopBar extends Component {


    render() {
        return (<div className="topbar">
            <div className="logobox">
                <Link to="/"><img src={Logo} alt="logo" /></Link>
            </div>
            <div className="menubox">
                {this.renderContent()}
            </div>
        </div>);
    }

    renderContent() {

        return (
            <ul>
                <li className="dyruplogo"><img src={DyrupLogo} alt="dyruplogo" /></li>
                <li><Link className="menubtn menuscene" to="/" >Back</Link></li>
                <li><Link className="menuclose" to="/" ></Link></li>
            </ul>
        )
    }


}



export default ChangeSceneTopBar