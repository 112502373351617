import React from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom";
import { stateAreaSelectProduct } from '../../../Redux/Actions/Area';


function RightBoxProductItem(props){
    const { product } = props
    var style = {backgroundColor:'#'+product.color};

    if(product.type==='Texture'){
        style = {...style, ...{backgroundImage: 'url('+product.image+')'}}
    }

    return (
        <Link to={"#"} className="productcolor" style={style} onClick={(e)=>{ 
            e.preventDefault()             
            //if(product.type==='Solid'){
                props.selectProduct(product)
            //}
        }}></Link> 
    )
}


const mapDispatchToProps = dispatch => {
    return {
        selectProduct: (product) => dispatch(stateAreaSelectProduct(product)),
    }
}
  
  
export default connect(null, mapDispatchToProps)(RightBoxProductItem);