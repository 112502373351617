import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom";

import RightBoxProductItem from './product.item'

class RightBoxProducts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collections: props.products ? props.collections : null,
            products: props.products ? props.products : null,
            searchtext: props.search ? props.search : '',            
        }
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.collections === null && nextProps.collections !== null) {
            this.setState({ products: nextProps.products, collections: nextProps.collections })
        }

        if (nextProps.search !== null) {
            this.setState({ searchtext: nextProps.search })
        }
    }

    getProductSet() {
        const { collections, products, searchtext } = this.state
        const { template, areaselect } = this.props
        if (collections == null || products == null) return null;

        if (template === null) return [];
        const area = template.template.areas[areaselect]
        if (!area) return [];


        var ncol = [];
        collections.forEach((item, inx) => {
            ncol[item.id] = item
            ncol[item.id]['products'] = []
        })

        var searchval = searchtext.toLowerCase()
        var fproducts = products.filter((product) => {
            if (!area.texture && product.type === 'Texture') return false;
            if (searchtext.trim() === '') return true;
            

            if( product.name.toLowerCase().indexOf(searchval) > -1){
                return (
                    product.name.toLowerCase().indexOf(searchval) > -1);
            }

            if( product.code.toLowerCase().indexOf(searchval) > -1){
                return (
                    product.code.toLowerCase().indexOf(searchval) > -1);
            }

            if( product.color.toLowerCase().indexOf(searchval) > -1){
                return (
                    product.color.toLowerCase().indexOf(searchval) > -1);
            }
          
        });

        fproducts.forEach((product, inx) => {
            product.cids.forEach((cid, jnx) => {
                if (ncol[cid] !== undefined) {
                    ncol[cid]['products'].push(product)
                }
            })
        })

        ncol = ncol.filter((value) => value.products.length > 0)

        return ncol;

    }

    render() {
        const collections = this.getProductSet()
        return (<div className="productset">              
            {collections && collections.map((collection, i) => {
                const key = 'productset' + collection.id
                return (<RenderCollection key={key} collection={collection} />)
            })}
        </div>);
    }
}


const RenderCollection = ({ collection }) => {

    const expand = collection.products.length > 18
    const [show, setShow] = useState(false)

    const products = expand ? (show ? collection.products : collection.products.slice(0, 18)) : collection.products

    return (
        <div className="productbox" id={"productbox" + collection.id}>
            <div className="titlebar clear">
                <h3>{collection.title}</h3>
                {expand && <Link className="showmore" to="#" onClick={(e) => { setShow(!show); e.preventDefault(); }}>{show ? 'Less' : 'More'}</Link>}
            </div>
            <div className="productlist clear">
                {products.map((product, i) => {
                    const key = 'product_' + collection.id + '_' + product.id
                    return (<RightBoxProductItem key={key} product={product} />)
                })}
            </div>
        </div>
    )
}


const mapStateToProps = state => {

    return {
        template: state.template.template,
        areaselect: state.area.area_selected,
        collections: state.application.collections,
        products: state.application.products,
        search: state.template.search,
    }
}


export default connect(mapStateToProps,null)(RightBoxProducts)