import React from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom";

import { stateTemplateSwitchLight } from '../../../Redux/Actions/Template'


function TemplateLightSwitch(props){   
    return (
        <Link className={"menubtn menulight "+(props.daylight?"day":"night")} to={loc=>{ return null }} onClick={(e)=>{
            e.stopPropagation()
            e.preventDefault()
            props.switchLight()
        }} >{props.daylight?"Day":"Night"}</Link>
    )
}


const mapStateToProps = state => {
    return {
        daylight: state.template.daylight
    }
}

const mapDispatchToProps = dispatch => {
    return {
        switchLight: () => dispatch(stateTemplateSwitchLight()),
    }
}  
  
export default connect(mapStateToProps, mapDispatchToProps)(TemplateLightSwitch);