import { fetchPost } from '../Service/ApiService'


export const getTemplateCategory = (type,resolve, reject) => {
    try {
        
        //Check token against existing user      
        fetchPost('template/category', type, {            
            success: (res) => { resolve(res); },
            fail: (res) => { reject(res); },
            error: (err) =>{ reject({status:false,message:"Unable to connect server"})  }
        })
    } catch (e) {
        reject({status:false,message:"Unable to process operation"});
    }
};
