import React from 'react'
import { connect } from 'react-redux'
import { Group } from 'react-konva'
import URLImage from '../../../Ui/Konvajs/URLImage'

import AreaMapTexture from './canvas.area.texture'


class CanvasAreaMap extends React.Component  {    

    constructor(props) {
        super(props);
     
        this.product = null
        this.mapimage = null
        this.mapbgimage = null
        this.mapbgnightimage = null
        this.daylight=this.props.daylight


        this.refTexture = React.createRef();
        
        this.handleMapLoad = this.handleMapLoad.bind(this)
        this.handleMapBgLoad = this.handleMapBgLoad.bind(this)
        this.handleMapBgNightLoad = this.handleMapBgNightLoad.bind(this)
        this.changeBgImage = this.changeBgImage.bind(this)
        this.filterMap = this.filterMap.bind(this)   
    }

    componentWillReceiveProps(nextProps){ 
        if(nextProps.areaproducts[this.props.areakey]!==undefined && nextProps.areaproducts[this.props.areakey]!==null){
            const product = nextProps.areaproducts[this.props.areakey]
            if(this.product===null || this.product.id!==product.id){
                this.product = product
                if(this.mapbgimage!==null){
                    this.mapbgimage.opacity(this.daylight?1:0).drawHitFromCache();
                    this.mapbgnightimage.opacity(this.daylight?0:1).drawHitFromCache();           
                    this.mapimage.opacity(0.9).cache().drawHitFromCache();
                    this.mapimage.getLayer().batchDraw()
                    this.changeTextureImage()
                }
            }
        }else if(this.product!==null){
            this.product = null
            if(this.mapbgimage!==null){
                this.mapbgimage.opacity(0).drawHitFromCache();
                this.mapbgnightimage.opacity(0).drawHitFromCache();
                this.mapimage.opacity(0).drawHitFromCache();
                this.mapimage.getLayer().batchDraw()
                this.changeTextureImage()
            }
        }

        if(this.daylight!==nextProps.daylight){
            this.daylight=nextProps.daylight
            if(this.product!=null){
                this.mapbgimage.opacity(this.daylight?1:0).drawHitFromCache();
                this.mapbgnightimage.opacity(this.daylight?0:1).drawHitFromCache();
                this.mapimage.getLayer().batchDraw()
                this.changeTextureImage()
            }
        }
    }

    changeTextureImage(){
        const { area } = this.props

        if(!area.texture) return;

        this.refTexture.current.changeTexture(this.product)
    }

    changeBgImage(){
        const url = this.props.path + (this.daylight?"day/":"night/")+ this.props.area.image
        var mapbgimage = this.mapbgimage
        var imageObj = new Image();
            imageObj.onload = function() {                        
            mapbgimage.image(imageObj).cache().drawHitFromCache(); 
            mapbgimage.getLayer().batchDraw()   
        };
        imageObj.src = url;
    }
    
    shouldComponentUpdate(nextProps, nextState) {        
        return false
    }

    handleMapLoad(image){
        this.mapimage = image
        this.mapimage.cache().drawHitFromCache();
        this.mapimage.getLayer().batchDraw()
    }

    handleMapBgLoad(image){
        this.mapbgimage = image
        this.mapbgimage.cache().drawHitFromCache();
    }

    handleMapBgNightLoad(image){
        this.mapbgnightimage = image
        this.mapbgnightimage.cache().drawHitFromCache();
    }
    

    render(){
        const { area, areakey, path } = this.props
        const url = path + "day/"+ area.image
        const urlnight = path + "night/"+ area.image

        return (
            <Group name={"map_"+areakey}>
                <URLImage ref="mapimagebg" src={url} x={area.offset.x} y={area.offset.y} opacity={0} onLoad={this.handleMapBgLoad} />
                <URLImage ref="mapimagebgnight" src={urlnight} x={area.offset.x} y={area.offset.y} opacity={0} onLoad={this.handleMapBgNightLoad} />
                {area.texture && 
                    <AreaMapTexture mapsrc={url} ref={this.refTexture} area={area} />
                }
                <URLImage ref="mapimage" src={url} x={area.offset.x} y={area.offset.y} opacity={0} filters={[this.filterMap]} globalCompositeOperation="multiply"
                onLoad={this.handleMapLoad} />
            </Group>
        )
    }

    filterMap(imageData){   
        if(this.product && this.product.type!=='Solid') return;

        const rgb = this.hexToRgb(this.product?this.product.color:'ffffff')     
        var nPixels = imageData.data.length
        for (var i = 0; i < nPixels; i += 4) {
            if(imageData.data[i+3]>0){
                imageData.data[i]=rgb.r;
                imageData.data[i+1]=rgb.g;
                imageData.data[i+2]=rgb.b;
            }
        }
    }

    hexToRgb(hex) {
        var result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
    }
      
}


const mapStateToProps = state => {
    return {
        areaselect: state.area.area_selected,
        areaproducts: state.area.area_products,
        daylight: state.template.daylight
    }
}


export default connect(mapStateToProps)(CanvasAreaMap)