import React, { Component } from 'react'
import { connect } from 'react-redux'
import Logo from '../../images/logo.png'

import DyrupLogo from '../../images/dyrup_logo.png'
import { Link } from "react-router-dom";

import TopBarCartButton from './TopBar/cartbutton'
import TemplateLightSwitch from './TopBar/lightswitch'
import TopSearchBar from './TopBar/searchbar'


import { stateTemplateSwitchHelp } from '../../Redux/Actions/Template'

class VisualizerTopBar extends Component {

    constructor(props) {
        super(props)
        console.log(props);
        this.state = {
            loading: props.loading,
            isLoaded: props.isLoaded,
            message: props.isLoaded,
        }

    }



    componentWillReceiveProps(nextProps) {
        var nstate = {}
        var changed = false
        if (nextProps.loading !== this.state.loading) {
            nstate['loading'] = nextProps.loading
            nstate['isLoaded'] = nextProps.isLoaded
            nstate['message'] = nextProps.message
            changed = true
        }

        if (changed) {
            this.setState(nstate)
        }
    }

    render() {
        return (<div id="topbar">
            <div className="logobox">
                <Link to="/"><img src={Logo} alt="logo" /></Link>
            </div>
            <div className="menubox">
                {this.renderContent()}
            </div>
        </div>);
    }


    renderContent() {
        const { loading, isLoaded } = this.state
        const { help } = this.props
        if (loading) return this.renderLoading()
        if (!isLoaded) return this.renderMessage()

        if (help) { document.body.classList.add('helpopen'); }
        if (!help) document.body.classList.remove('helpopen');

        return (
            <ul>
                <li>
                    <Link className="menubtn menuhelp" to="#" onClick={(e) => { e.preventDefault(); this.props.switchHelp(!help) }} >{help ? "Close" : "Help"}</Link>
                </li>
                <li className="lightonoff"><TemplateLightSwitch /></li>
                <li><Link className="menubtn menuscene" to="/scene" >Scene</Link></li>
                <li className="psearchbar"><TopSearchBar /></li>
                <li><TopBarCartButton /></li>
                <li className="dyruplogo"><img src={DyrupLogo} alt="dyruplogo" /></li>
                <li><Link className="menuclose" to="#" onClick={(e) => { e.preventDefault(); return false; }} ></Link></li>
            </ul>
        )
    }

    renderLoading() {
        return (
            <div className="loading"><span>Loading...</span></div>
        )
    }

    renderMessage() {
        const { message } = this.state
        if (message === '') return null;

        return (
            <div className="message"><span>{message}</span></div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.template.loading,
        message: state.template.message,
        isLoaded: state.template.isLoaded,
        help: state.template.help,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        switchHelp: (state) => dispatch(stateTemplateSwitchHelp(state)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisualizerTopBar)