import { fetchGet } from '../Service/ApiService'


export const loadTemplate = (tid, resolve, reject) => {
    try {
        //Check token against existing user      
        fetchGet('template/'+tid, null, {            
            success: (res) => { resolve(res); },
            fail: (res) => { reject(res); },
            error: (err) =>{ reject({status:false,message:"Unable to connect server"})  }
        })
    } catch (e) {
        reject({status:false,message:"Unable to process operation"});
    }
};
