import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button,Alert } from 'reactstrap';
import { stateCartHidePopup, stateCartHideEnquiry } from '../../../Redux/Actions/Cart'
import { sendEnquiry } from '../../../Model/Enquiry'
import ItemErrors from '../../../Ui/ItemErrors/itemerrors'

class VisualizerEnquirey extends Component {

    constructor(props) {
        super(props)
        this.state = {
            enquirydata: {
                name: '',
                email: '',      
                phone:'',
                message: '',
                cartitems:[this.props.cart?this.props.cart:''],
              },
              loading: false,
              errors: [],
              info:'',

        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.handleChange = this.handleChange.bind(this);
    }


    handleSubmit (){        
        this.setState({ loading: true })
        sendEnquiry(this.state.enquirydata,(res) => {
            var successstate = { message: null, errors: [], info: '', loading: false }
            if (res.message) {
              successstate.info = res.message;
            }
            this.setState(successstate);

            this.props.hidePopup()
            this.props.hideEnquiry()
          
        }, (res) => {
            var failstate = {  message: null, errors: [], info: '', loading: false }
            if (res.message) {
              failstate.message = res.message;
            }
            if (res.errors) {
              failstate.errors = res.errors;
            }
            this.setState(failstate);
         
        });
       
    }
    handleChange(event) {
        var enquirydata = this.state.enquirydata;
        enquirydata[event.target.dataset.datakey] = event.target.value;

    }
    closeModal() {
        this.props.hidePopup()
        this.props.hideEnquiry()
    }


    render() {     
        return (
            <div className="enquiryform">
                <form onSubmit={this.handleSubmit}>
                {this.state.message && <Alert color="warning">{this.state.message}</Alert>}
                {this.state.info && <ItemErrors info errorData={this.state.info} />}
                    <input type="text" data-datakey="name" placeholder="Name" required onChange={this.handleChange} />
                    {this.state.errors.name && <ItemErrors errorData={this.state.errors.name} />}                    
                    <input type="text" data-datakey="email" placeholder="Email" required onChange={this.handleChange} />
                    {this.state.errors.email && <ItemErrors errorData={this.state.errors.email} />}                    
                    <input type="text" data-datakey="phone" placeholder="Phone" inputMode="numeric" required onChange={this.handleChange}/>
                    {this.state.errors.phone && <ItemErrors errorData={this.state.errors.phone} />}
                    <textarea data-datakey="message" placeholder="Message" rows="3" cols="40" onChange={this.handleChange} />
                    <div className="cartbtn">
                    {this.state.loading &&
                        <Button className="send" variant="outline-primary" size="sm" disable >Loading...</Button> }
                       {!this.state.loading &&  <Button className="send" variant="outline-primary" size="sm" onClick={this.handleSubmit} >Send</Button>}
                        <Button className="cancel" variant="outline-primary" size="sm" onClick={this.closeModal}>Cancel</Button>
                    </div>
                </form>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        popup: state.cart.popup,
        enquiry: state.cart.enquiry,
        cart: state.cart.cart
    }
}



const mapDispatchToProps = dispatch => {
    return {
        hidePopup: () => dispatch(stateCartHidePopup()),
        hideEnquiry: () => dispatch(stateCartHideEnquiry()),

    }
}



export default connect(mapStateToProps, mapDispatchToProps)(VisualizerEnquirey)