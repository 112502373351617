import React, { Component } from 'react'
import { connect, ReactReduxContext, Provider } from 'react-redux'
import { Stage, Layer, Group } from 'react-konva'

import URLImage from '../../Ui/Konvajs/URLImage'
import CanvasAreaMap from './Canvas/canvas.area.map'
import CanvasAreaHover from './Canvas/canvas.area.hover'

class VisualizerCanvas extends Component {    

    constructor(props) {
        super(props)
        this.state = {
            template: props.template?props.template:null,
            stagesize:this.getStageSize()
        }
        
        this.daylight=this.props.daylight
        this.baseimage_day = null
        this.baseimage_night = null
        this.updateDimensions = this.updateDimensions.bind(this)
        this.handleDayImage = this.handleDayImage.bind(this)
        this.handleNightImage = this.handleNightImage.bind(this)

        
    }

    componentDidMount(){
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.template === null && nextProps.template!==null) {
            this.setState({ template: nextProps.template })
        }
        
        if(nextProps.daylight!==this.daylight){
            this.daylight = nextProps.daylight
            if(this.baseimage_day!==null){
                this.baseimage_day.opacity(this.daylight?1:0)
                this.baseimage_day.getLayer().batchDraw()
            }
            if(this.baseimage_night!==null){
                this.baseimage_night.opacity(this.daylight?0:1)
                this.baseimage_day.getLayer().batchDraw()
            }
        }
    }
    
    shouldComponentUpdate(nextProps, nextState) {   
        if(this.state.template===null && nextState.template!==null) return true
        if(this.state.stagesize.width!==nextState.stagesize.width || this.state.stagesize.height!==nextState.stagesize.height) return true
        return false
    }

    updateDimensions(){ 
        const { template } = this.state
        if(template===null) return null

        this.setState({stagesize:this.getStageSize()})
    }

    handleDayImage(image){
        this.baseimage_day=image
    }

    handleNightImage(image){
        this.baseimage_night=image
    }

    render(){
        const {template} = this.state
        if(template===null) return null
        const stagesize = this.getStageSize()
        const tdata = template.template       
        const scale = this.scaleToFrame(stagesize, tdata.size)
        const pos = this.boundary({x: 0, y: 0}, {width: tdata.size.width * scale, height: tdata.size.height * scale}, stagesize);
        const areas = tdata.areas
        const areakeys = Object.keys(areas)
        return (<div id="vzcanvas">
            <ReactReduxContext.Consumer>
            {({ store }) => (
            <Stage width={stagesize.width} height={stagesize.height}>
                <Layer scaleX={scale} scaleY={scale} x={pos.x} y={pos.y} name="baselayer" >
                    <Group x={-tdata.size.width/2} y={-tdata.size.height/2} >
                        <URLImage src={template.path+"night/base_image.jpg"}  width={tdata.size.width} height={tdata.size.height} opacity={0}
                            onLoad={this.handleNightImage}
                        />
                        <URLImage src={template.path+"day/base_image.jpg"}  width={tdata.size.width} height={tdata.size.height}
                            onLoad={this.handleDayImage}
                        />
                        <Group name="areagroup">
                            <Provider store={store}>
                                {areakeys.map((key,index)=>{
                                    const area = areas[key]
                                    return (
                                        <CanvasAreaMap key={"area_"+index} areakey={key} area={area} path={template.path} />
                                    )
                                })}
                                {areakeys.map((key,index)=>{
                                    const area = areas[key]
                                    return (
                                        <CanvasAreaHover key={"area_"+index} areakey={key} area={area} path={template.path} />
                                    )
                                })}
                            </Provider>
                        </Group>                        
                    </Group>
                </Layer>
            </Stage>
            )}
            </ReactReduxContext.Consumer>
        </div>);
    }

    getStageSize(){ 
        if(this.props.template===null) return 0
        const actualwidth = this.props.template.template.size.width
        const actualheight = this.props.template.template.size.height  
        const boxwidth = window.innerWidth-(window.innerWidth>991?300:0)
        var boxheight = window.innerWidth>991 ? window.innerHeight - 60 : (window.innerWidth * (actualheight / actualwidth))    
        
        return {width:boxwidth, height:boxheight}        
    }

    frameToSize(nsize, fsize) {
        const ratw = nsize.width / fsize.width
        const rath = nsize.height / fsize.height
        var rsize={width:0,height:0}
        if (ratw < rath) {
            rsize.width = Math.floor(fsize.width * ratw)
            rsize.height = Math.floor(fsize.height * ratw)
        } else {
            rsize.width = Math.floor(fsize.width * rath)
            rsize.height = Math.floor(fsize.height * rath)
        }
        return rsize;
    }

    scaleToFrame(stagesize, imageisze) {
        var nsize = this.frameToSize(stagesize, imageisze)
        return Math.min((nsize.width / imageisze.width), (nsize.height / imageisze.height))
    }

    boundary(pos, size, stage) {
        //const { zoom } = this.state
        var mid;
        if (size.width > stage.width) {
            mid = (size.width / 2);
            pos.x = (pos.x > mid) ? mid : pos.x;
            pos.x = ((pos.x + mid) < stage.width) ? stage.width - mid : pos.x;
        } else {
            pos.x = stage.width / 2;
        }
        if (size.height > stage.height) {
            mid = (size.height / 2);
            pos.y = (pos.y > mid) ? mid : pos.y;
            pos.y = ((pos.y + mid) < stage.height) ? stage.height - mid : pos.y;
        } else {
            pos.y = stage.height / 2;
        }
        return pos;
    }

}



const mapStateToProps = state => {
    return {
        template: state.template.template,
        daylight: state.template.daylight
    }
}


export default connect(mapStateToProps, null)(VisualizerCanvas)