import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactModal from 'react-modal'
import { stateCartHidePopup, stateCartShowEnquiry,stateCartHideEnquiry} from '../../../Redux/Actions/Cart'
import { styleCart } from '../../../Theme/default/styles'
import { Button} from 'reactstrap';

import './cart.css'



import VisualizerCartItems from './cart.items'
import VisualizerEnquirey from './enquires'

import { PopupHeader, PopupBody } from './cart.popup.node'

class VisualizerCartPopup extends Component {    

    constructor(props) {
        super(props)
        this.state = {
            enquiry:this.props.enquiry ? this.props.enquiry:false,
            popup:this.props.popup ? this.props.popup:false
        }
        this.closeModal = this.closeModal.bind(this)
        this.enquiry = this.enquiry.bind(this)
    }
    
    componentWillReceiveProps(nextProps, nextState) {
        if (this.props.popup !==nextProps.popup) {
            this.setState({ popup: nextProps.popup })
        }
        if (this.props.enquiry !== nextProps.enquiry) {
            this.setState({ enquiry: nextProps.enquiry })
        }
        /*if((this.props.popup!==nextProps.popup) || (this.props.enquiry!==nextProps.enquiry)  ) return true
       if(this.props.enquiry!==nextProps.enquiry  ) return true
        return false*/
    }

    closeModal(){
      this.props.hidePopup()
      this.props.hideEnquiry()
    }

    enquiry(){
        this.props.showEnquiry()
      }
   

    render(){
       // console.log(this.props.enquiry);
        const { popup,enquiry } = this.state
        const { cart } = this.props

        console.log(cart);

        return (<ReactModal isOpen={popup} style={styleCart} shouldCloseOnOverlayClick={true} onRequestClose={this.closeModal} 
            contentLabel="Cart" ariaHideApp={false} >
                <PopupHeader title="Cart" onClose={this.closeModal} />
                <PopupBody>
                {!enquiry &&  <VisualizerCartItems/> }
                {enquiry&&  <VisualizerEnquirey/> }
                {(!enquiry  && cart.length > 0) && <div className="cartbtn"><Button variant="outline-primary" size="sm" onClick={this.enquiry}>Enquiry</Button></div>   }
                </PopupBody>
          </ReactModal>);
    }
}



const mapStateToProps = state => {
    return {
        popup: state.cart.popup,
        enquiry:state.cart.enquiry,
        cart: state.cart.cart,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hidePopup: () => dispatch(stateCartHidePopup()),
        showEnquiry: () => dispatch(stateCartShowEnquiry()),
        hideEnquiry:()=>dispatch(stateCartHideEnquiry()),

    }
}  

export default connect(mapStateToProps, mapDispatchToProps)(VisualizerCartPopup)