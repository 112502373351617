import { fetchPost } from '../Service/ApiService'


export const sendEnquiry = (data,resolve, reject) => {
   // console.log(data);
    try {  
        fetchPost('enquiry/send', data, {            
            success: (res) => { resolve(res); },
            fail: (res) => { reject(res); },
            error: (err) =>{ reject({status:false,message:"Unable to connect server"})  }
        })
    } catch (e) {
        reject({status:false,message:"Unable to process operation"});
    }
};