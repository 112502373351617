import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Preload } from 'react-preload'

import { stateTemplateLoadingInit, stateTemplateReset  } from '../../Redux/Actions/Template'

import './visualizer.css'
import VisualizerTopBar from './visualizer.topbar'
import VisualizerRightBox from './visualizer.rightbox'
import VisualizerCanvas from './visualizer.canvas'
import VisualizerBottomBar from './visualizer.bottombar'
import VisualizerShareBar from './socialshare'
import VisualizerProductBar from './visualizer.productbar'
import VisualizerCartPopup from './Cart/cart.popup'
import { useParams } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill';
import VisualizerHelp from './visualizer.help'


function Visualizer(props){

  const { tid } = useParams()
  const [imageLoaded, setImageLoaded] = useState(false)


 
  const templateId = tid===undefined?0:tid     

  smoothscroll.polyfill();

  if( templateId >0 && props.templateId!==templateId){
    
    props.resetTemplate()
  }
  
  if(!props.loaderror && props.template===null && !props.isLoaded && !props.loading){
    //setTimeout(function(){
      props.loadTemplate(templateId)
    //},200)
    //return (<ApplicationLoading/>)
  }


  var images=[];
  if(props.template){
    var path = props.template.path;

    images.push(path + "day/base_image.jpg")
    images.push(path + "night/base_image.jpg")

    var areakeys = Object.keys(props.template.template.areas)
    
    areakeys.forEach((key)=>{
      var area = props.template.template.areas[key]
      images.push(path + 'day/' + area.image)
      images.push(path + 'night/' + area.image)
    })
  }

  var loadingIndicator = (<ApplicationLoading/>);
  return (
    <div id="page-visulaizer">
      <VisualizerTopBar/>
      <div id="page-content">
        {props.template && <Preload
          loadingIndicator={loadingIndicator}
          images={images}                
          resolveOnError={true}
          mountChildren={imageLoaded}
          onSuccess={()=>{ if(props.template){ setImageLoaded(true); } }}
        >
          <VisualizerCanvas/>
          <VisualizerBottomBar/>
          <VisualizerShareBar/>
          <VisualizerProductBar/>
        </Preload>}
        {!props.template && <ApplicationLoading/>}
      </div>
      <div id="page-right">
        <VisualizerRightBox/>
      </div>
      <VisualizerCartPopup />
      <VisualizerHelp/>
    </div>
  )
}



const ApplicationLoading =()=>(
  <div id="apploading"></div>
)



const mapStateToProps = state => {
  return {
      template: state.template.template,
      templateId: state.template.templateId,
      loading: state.template.loading,
      isLoaded: state.template.isLoaded,
      loaderror: state.template.loaderror
  }
}

const mapDispatchToProps = dispatch => {
  return {
      loadTemplate: (tid) => dispatch(stateTemplateLoadingInit(tid)),
      resetTemplate: () => dispatch(stateTemplateReset())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Visualizer);