import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { stateCartUpdateItems } from '../../../Redux/Actions/Cart';


function ProductBarAddToCart({cart, updateCart, productId}){  
    const { addToast } = useToasts()

    const hasItem = (cart && cart.indexOf(productId)>-1)

    const handleClick = () => {
        if(!hasItem){
            cart.push(productId)
            cart = cart.filter(pid => true);
        }else{
            cart = cart.filter(pid => pid !== productId);
        }
        updateCart(cart)
        addToast('Item '+(hasItem?'removed from':'added to')+' cart');
    }

    return (<Link className="addbtn" to="#" onClick={(e)=>{ 
        e.preventDefault(); 
        handleClick();
        return false; 
    }}>{hasItem?'Remove':'Add'}</Link>)
}


const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateCart: (cart) => dispatch(stateCartUpdateItems(cart)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductBarAddToCart)