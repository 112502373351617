import React from 'react';
import { connect } from 'react-redux'
import { stateAreaSelect, stateAreaHoverIn, stateAreaHoverOut } from '../../../Redux/Actions/Area'



class BottomBarThumBox extends React.Component {    

    constructor(props) {
        super(props);

        this.state = {
            hover:false,
            selected:(this.props.areakey==='LEFT'),
            product: null
        }
    }

    componentWillReceiveProps(nextProps){      
        const { hover, selected, product } = this.state

        if(!hover && nextProps.areahover===this.props.areakey){
            this.setState({hover:true})
        }
        if(hover && nextProps.areahover!==this.props.areakey){
            this.setState({hover:false})
        }

        if(!selected && nextProps.areaselect===this.props.areakey){
            this.setState({selected:true})
        }
        if(selected && nextProps.areaselect!==this.props.areakey){
            this.setState({selected:false})
        }
        if(nextProps.areaproducts[this.props.areakey]!==undefined && nextProps.areaproducts[this.props.areakey]!==null){
            const inproduct = nextProps.areaproducts[this.props.areakey]
            
            if(product===null || product.id!==inproduct.id){
                this.setState({product:inproduct})
            }
        }else if(product!==null){
            this.setState({product:null})
        }

    }
    
    shouldComponentUpdate(nextProps, nextState) {
        var flg=false;
        if(this.state.hover!==nextState.hover) flg=true
        if(this.state.selected!==nextState.selected) flg=true
        if(this.state.product===null || (nextState.product!==null && this.state.product.id!==nextState.product.id)) flg=true

        return flg
    }

    render(){
        const { area, areakey } = this.props
        const { hover, selected, product } = this.state

        var style = {}
        if(product!==null){
            style = {...style, ...{borderColor:'#'+product.color}}            
        }

        return (
            <div className={"clear areathum"+(hover?" hover":"")+((this.props.areahover===null && selected)?" selected":"")+(product?" hascolor":"")} onMouseEnter={()=>{
                this.props.stateAreaHoverIn(areakey)
            }} onMouseLeave={()=>{
                this.props.stateAreaHoverOut(areakey)
            }} onClick={()=>{
                this.props.stateAreaSelect(areakey)
            }}>
            <span className={"icon icon_"+areakey} style={style}></span>
            <span className="title">{area.name}</span>
        </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        areahover: state.area.area_hover,
        areaselect: state.area.area_selected,
        areaproducts: state.area.area_products
    }
}

const mapDispatchToProps = dispatch => {
  return {
    stateAreaSelect: (area) => dispatch(stateAreaSelect(area)),
    stateAreaHoverIn: (area) => dispatch(stateAreaHoverIn(area)),
    stateAreaHoverOut: (area) => dispatch(stateAreaHoverOut(area)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BottomBarThumBox)