const defaultState = {
    loading: false,
    template:null,
    templateId:0,
    loaderror: false,
    areas: [],
    message:'',
    isLoaded:false,
    daylight: true,
    help:false,
    search:'',
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {

        case 'TEMPLATE_RESET':
            return defaultState;

        case 'TEMPLATE_LOADING_INIT':
            return Object.assign({}, state, {
                loading: true,
                isLoaded: false,
                templateId: action.templateId,
                template:null,
                loaderror:false
            });

        case 'TEMPLATE_LOADING_DONE':
            return Object.assign({}, state, {
                loading: false,
                template: action.template,
                message:'',
                isLoaded: true,
                loaderror: false
            }); 

        case 'TEMPLATE_LOADING_FAIL':
            return Object.assign({}, state, {
                loading: false,
                message: action.message,
                isLoaded: false,
                loaderror: true
            });  

        case 'TEMPLATE_SWITCH_LIGTH':
            if(!state.isLoaded) return state;
            return Object.assign({}, state, {
                daylight: !state.daylight
            });  

        case 'TEMPLATE_SWITCH_HELP':                
            return Object.assign({}, state, {
                help: action.help
            });  

        case 'TEMPLATE_SEARCH':
            return Object.assign({}, state, {
                search: action.search
            });
            
        default:
            return state;
    }
}