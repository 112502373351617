
export const stateCartUpdateItems = (cart) => {
    return {
        type: 'CART_UPDATE_ITEMS',
        cart: cart
    };
};

export const stateCartShowPopup = () => {
    return {
        type: 'CART_SHOW_POPUP'
    };
};


export const stateCartHidePopup = () => {
    return {
        type: 'CART_HIDE_POPUP'
    };
};

export const stateCartShowEnquiry = () => {   
    return {
        type: 'CART_SHOW_ENQUIRY',
       
    };
};

export const stateCartHideEnquiry = () => {
    return {
        type: 'CART_HIDE_ENQUIRY'
    };
};
