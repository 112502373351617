import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    FacebookShareButton,    
    TwitterShareButton,   
    PinterestShareButton,
    EmailShareButton,   
    FacebookIcon,
    TwitterIcon, 
    PinterestIcon,   
    EmailIcon,
} from 'react-share';

class SocialShare extends Component {

    constructor(props){
        super(props)
       
        this.state={
         socialopen:false,
       
    }
}
    render() {
      
        const {templateId } = this.props
        const Url = window.location.origin + '/template/'+ templateId;
        return (
            <div className={this.state.socialopen ? "social_shareicon active" : "social_shareicon"}>
                <button  onClick={() => { this.setState({socialopen:!this.state.socialopen}) }} className="innrshare"></button>
                <ul className="share_list_icon">
                    <li>
                        <FacebookShareButton url={Url}   >
                            <FacebookIcon size={32} round className="icons_width" bgStyle={{fill:'#e3e3e3'}} iconFillColor={"#676767"}/>
                        </FacebookShareButton>                      
                    </li>                   
                    <li>
                        <TwitterShareButton url={Url}  >
                            <TwitterIcon size={32} round  bgStyle={{fill:'#e3e3e3'}} iconFillColor={"#676767"} />                           
                        </TwitterShareButton>
                    </li>
                    <li>
                        <PinterestShareButton url={Url} media={Url}  >
                            <PinterestIcon size={32} round bgStyle={{fill:'#e3e3e3'}} iconFillColor={"#676767"}/>                            
                        </PinterestShareButton>
                    </li>
                   
                    <li>
                        <EmailShareButton url={Url}  >
                            <EmailIcon size={32} round bgStyle={{fill:'#e3e3e3'}} iconFillColor={"#676767"}/>                           
                        </EmailShareButton>
                    </li>
                </ul>
                
                           
            </div>
        )
    };
}

const mapStateToProps = state => {
    return {       
        templateId: state.template.templateId,      
    }
  }


export default connect(mapStateToProps)(SocialShare)