import React from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom";

import { stateCartShowPopup } from '../../../Redux/Actions/Cart'


function TopBarCartButton({cart, popup, showPopup}){  
 
    return (
        <Link className={"menubtn menucart"} to={loc=>{ return null }} onClick={(e)=>{
            e.stopPropagation()
            e.preventDefault()  
            showPopup()          
        }} >{cart?cart.length:0}</Link>
    )
}


const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
        popup: state.cart.popup
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showPopup: () => dispatch(stateCartShowPopup()),
    }
}  
  
export default connect(mapStateToProps, mapDispatchToProps)(TopBarCartButton);