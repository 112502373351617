

export const styleCart = {
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding:0,
        boxShadow: '0 2px 6px 0 rgba(0,0,0,.2)'
    },
    overlay: {
        zIndex : 5
    }
};