import { stringify } from 'query-string';

export const server = {
    //url: 'https://www.web-trendz.com/paintvisualizer/api/v1/site/'
    //url: 'http://localhost:8000/api/v1/site/'
    url: 'https://visualizer.univest.com.sa/api/v1/site/'
};

export const apiCallback = {
    success: null,
    fail: null,
    error: null,
};

export const AUTH_USER_KEY = "ts_auth_user";

export const apiHeader = {
    'Accept': 'application/json',
    // 'Content-Type': 'application/x-www-form-urlencoded',
    'pragma': 'no-cache',
    'cache-control': 'no-cache'
};


export const fetchApi = async (path, data, method, callback, header) => {

    callback = { ...apiCallback, ...callback };
    header = { ...apiHeader, ...header };

    var user = JSON.parse(localStorage.getItem("user"));//AUTH_USER_KEY

    if (user) {
        header = { ...header, ...{ Authorization: 'Bearer ' + user.token } };
    }
    if (!(data instanceof FormData)) {
        header = {
            ...header, ...{
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
            }
        };
    }
    var lang = localStorage.getItem("lang");
    header = { ...header, ...{ 'Accept-Language': lang } };

    try {
        return fetch(server.url + path, {
            method: method,
            headers: header,
            //redirect: 'follow',
            //mode: 'no-cors',
            body: data ? ((data instanceof FormData) ? data : stringify(data)) : null
        }).then((response) => {
            try {
                return response.json();
            } catch (e) {
                //console.log('response parse', e);
            }
            return {};
        }).then((responseJson) => {
            try {
                if (!responseJson.status) {
                    if (callback.fail) callback.fail(responseJson);
                } else {
                    if (callback.success) callback.success(responseJson);
                }
            } catch (e) {
                callback.error("Application error");
            }
            return responseJson;
        }).catch((error) => {
            if (callback.error) callback.error(error.me);
        });
    } catch (e) {
        callback.error(e.message);
    }
}

export const fetchGet = (path, data, callback, header) => {
    return fetchApi(path, data, 'GET', callback, header);
}
export const fetchPost = (path, data, callback, header) => {
    return fetchApi(path, data, 'POST', callback, header);
}
export const fetchPut = (path, data, callback, header) => {
    return fetchApi(path, data, 'PUT', callback, header);
}
export const fetchDelete = (path, data, callback, header) => {
    return fetchApi(path, data, 'DELETE', callback, header);
}