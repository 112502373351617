import React from 'react'
import { connect } from 'react-redux'

import { stateTemplateSearch } from '../../../Redux/Actions/Template'

function TopSearchBar(props){
    return (
        <div className="searchbar">
            <input type="text" placeholder="Search" onChange={(event) => { props.search(event.target.value) }} />
        </div>
    )
}

const mapStateToProps = state => {   
    return {
        search: state.template.search
    }
}

const mapDispatchToProps = dispatch => {
    return {
        search: (data) => dispatch(stateTemplateSearch(data)),
    }
}  
  
export default connect(mapStateToProps, mapDispatchToProps)(TopSearchBar);