import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications'

import Application from './Components/Application/application'
import store from './Redux';


const AppToast = ({ appearance, children }) => (
  <div className="snakbar" >
    {children}
  </div>
);


class App extends Component {
  render() {
    return (
      <Provider store={store}>       
        <ToastProvider autoDismiss autoDismissTimeout={5000} components={{ Toast: AppToast }} placement="bottom-center">
          <Application />
        </ToastProvider>
      </Provider>
    );
  }
}
export default App;
