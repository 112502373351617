import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Visualizer from '../Components/Visualizer/visualizer';
import Scene from '../Components/Scene/scene';


export default function AppNavigation(){
    return (<Router basename={'/'}>
        <Switch>
            <Route exact path="/">
                <Visualizer/>
            </Route>
            <Route exact path="/template/:tid">
                <Visualizer/>
            </Route>
            <Route path="/scene">
                <Scene/>
            </Route>
        </Switch>
    </Router>
    )

}