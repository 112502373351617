const defaultState = {
    area_hover:null,
    area_selected: 'LEFT',
    area_products: {}   
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {

        case 'TEMPLATE_RESET':
            return defaultState;
            
        case 'AREA_HOVER_IN':
            return Object.assign({}, state, {
                area_hover: action.area,
            });

        case 'AREA_HOVER_OUT':
            if(state.area_hover!==action.area) return state
            return Object.assign({}, state, {
                area_hover: null
            });

        case 'AREA_SELECT_AREA':
            return Object.assign({}, state, {
                area_selected: action.area,
            });

        case 'AREA_SELECT_PRODUCT':
            const nproducts = {}
            nproducts[state.area_selected] = action.product
            return Object.assign({}, state, {
                area_products: {...state.area_products, ...nproducts},
            });

        default:
            return state;
    }
}