import React from 'react'

import RightBoxCollection from './RightBox/collections'
import RightBoxProducts from './RightBox/products'

function VisualizerRightBox(){
    
    return (
        <div id="rightbox">            
            <div id="rightboxcontent">
                <RightBoxCollection/>
                <RightBoxProducts/>
            </div>
        </div>
    );
    
}

export default VisualizerRightBox;