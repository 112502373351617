import React, { useState } from 'react'

import MoreInfoReleated from './productbar.moreinfo.related'
import MoreInfoColorSchemes from './productbar.moreinfo.schemes'


function ProductBarMoreInfo(props){    
    const { product } = props
    const [tab, setTab] = useState(1)

    const acttab = product.type==='Solid'?tab:1;
    
    return (
        <div className="moreinfoin">
            <ul className="tabheader clear">
                <li className={acttab===1?"active":""} onClick={(e)=>{ setTab(1) }}>Color Details</li>
                {product.type==='Solid' && <li className={acttab===2?"active":""} onClick={(e)=>{ setTab(2) }}>Similar Colors</li>}
                {product.type==='Solid' && <li className={acttab===3?"active":""} onClick={(e)=>{ setTab(3) }}>Color Schemes</li>}
            </ul>
            <div className="tabbody">
                {acttab===1 && <MoreInfoDetail product={product} />}
                {acttab===2 && product.type==='Solid' && <MoreInfoReleated product={product} />}
                {acttab===3 && product.type==='Solid' && <MoreInfoColorSchemes product={product} />}                
            </div>
        </div>
    )
}

function MoreInfoDetail(props){ 
    const { product } = props

    const rgb = hexToRgb('#'+product.color)

    return (<div className="details clear">
        <div className="halfbox">
            <p><b>Name</b><br/>{product.name}</p>
            <p><b>Code</b><br/>{product.code}</p>        
        </div>
        <div className="halfbox">
            <p><b>Hex Value</b><br/>#{product.color.toUpperCase()}</p>
            <p><b>RGB</b><br/><b>R: </b>{rgb.r}, <b>G: </b>{rgb.g}, <b>B: </b>{rgb.b}</p>        
        </div>
    </div>)
}

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
  


export default ProductBarMoreInfo;