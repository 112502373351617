
export const stateTemplateReset = () => {
    return {
        type: 'TEMPLATE_RESET'
    };
};


export const stateTemplateLoadingInit = (tid) => {
    return {
        type: 'TEMPLATE_LOADING_INIT',
        templateId: tid,
        loading: true
    };
};

export const stateTemplateLoadingDone = (data) => {
    return {
        type: 'TEMPLATE_LOADING_DONE',
        template: data,
    };
};

export const stateTemplateLoadingFail = (message) => {
    return {
        type: 'TEMPLATE_LOADING_FAIL',
        message: message,
    };
};

export const stateTemplateSwitchLight = () => {
    return {
        type: 'TEMPLATE_SWITCH_LIGTH'
    };
};

export const stateTemplateSwitchHelp = (state) => {
    return {
        type: 'TEMPLATE_SWITCH_HELP',
        help: state
    };
};

export const stateTemplateSearch = (search) => {
    return {
        type: 'TEMPLATE_SEARCH',
        search: search
    };
};

