import { loadTemplate } from '../../Model/Template'
import { stateTemplateLoadingDone, stateTemplateLoadingFail } from '../Actions/Template'
import { stateAreaSelect } from '../Actions/Area'

export default function templateMiddleware({dispatch}) {
    
    //Prepare const if require here
    

    return next => action => {
        //console.log(action)
        switch (action.type) {

            case 'TEMPLATE_LOADING_INIT': {
                //init template loading
                loadTemplate(action.templateId, (res)=>{
                    
                    dispatch(stateTemplateLoadingDone(res.data))
                    dispatch(stateAreaSelect(res.data.template.area))
                },(res)=>{
                    dispatch(stateTemplateLoadingFail(res.message?res.message:'Unable to load template'))
                })
                return next(action);
            }

            default: {
                return next(action);
            }
        }
    };
}