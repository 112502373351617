import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-konva';

class URLTexture extends Component {
    state = {
      image: null
    };

    componentDidMount() {
      this.loadImage();
    }

    componentDidUpdate(oldProps) {
      if (oldProps.src !== this.props.src) {
        this.loadImage();
      }
    }

    componentWillUnmount() {
      this.image.removeEventListener('load', this.handleLoad);
    }

    loadImage() {
      this.image = new window.Image();
      this.image.crossOrigin = 'Anonymous';
      this.image.addEventListener('load', this.handleLoad);       
      this.image.src = this.props.src;  

      this.mapimage = new window.Image();
      this.mapimage.crossOrigin = 'Anonymous';
      this.mapimage.addEventListener('load', this.handleLoad);       
      this.mapimage.src = this.props.mapsrc;  
    }

    handleLoad = () => {
      if(!(this.image && this.mapimage)) return;

      const fw = this.props.txtSize.w>this.props.size.w?this.props.txtSize.w:this.props.size.w
      const fh = this.props.txtSize.h>this.props.size.h?this.props.txtSize.h:this.props.size.h

      var canvas = document.createElement("canvas");
      canvas.width = this.props.txtSize.w
      canvas.height = this.props.txtSize.h

      const sx = this.props.size.w / fw;
      const sy = this.props.size.h / fh;

      var ctx = canvas.getContext("2d");
      var pattern = ctx.createPattern(this.image, 'repeat');          
      ctx.scale(sx,sy)
      ctx.fillStyle = pattern;
      ctx.fillRect(0, 0, fw,  fh);
      

      var canvas2 = document.createElement("canvas");
      canvas2.width = this.props.size.w
      canvas2.height = this.props.size.h

      var ctx2 = canvas2.getContext("2d");
      ctx2.drawImage(canvas, 0, 0, fw, fh);
      ctx2.globalCompositeOperation = "destination-in";
      ctx2.drawImage(this.mapimage, 0, 0, this.props.size.w, this.props.size.h);

      this.setState({
        image: canvas2
      });
      
      this.props.onLoad(this.imageNode);
    };

    render() {
      if(this.state.image===null) return null;
      return (
        <Image {...this.props} image={this.state.image} ref={node => {
            this.imageNode = node;
        }}/>
      );
    }

}

URLTexture.propTypes = {
    onLoad: PropTypes.func
}

URLTexture.defaultProps = {
  onLoad: (node)=>{},
}

export default URLTexture