const defaultState = {
    cart:[],
    popup: false,
    enquiry:false
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {

        case 'TEMPLATE_RESET':
            return defaultState;
        
        case 'CART_UPDATE_ITEMS':
            return Object.assign({}, state, {
                cart: action.cart
            });
        
        case 'CART_SHOW_POPUP':
            return Object.assign({}, state, {
                popup: true
            });
        
        case 'CART_HIDE_POPUP':
            return Object.assign({}, state, {
                popup: false
            });

            case 'CART_SHOW_ENQUIRY':
                console.log('tedttt');
                return Object.assign({}, state, {
                    enquiry: true
                });
            
            case 'CART_HIDE_ENQUIRY':
                return Object.assign({}, state, {
                    enquiry: false
                });

               
                
            
        default:
            return state;
    }
}