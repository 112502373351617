
export const stateAreaHoverIn = (area) => {
    return {
        type: 'AREA_HOVER_IN',
        area: area
    };
};

export const stateAreaHoverOut = (area) => {
    return {
        type: 'AREA_HOVER_OUT',
        area: area
    };
};

export const stateAreaSelect = (area) => {
    return {
        type: 'AREA_SELECT_AREA',
        area: area
    };
};

export const stateAreaSelectProduct = (product) => {
    return {
        type: 'AREA_SELECT_PRODUCT',
        product: product
    };
};