import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ProductbarMoreinfo from './BottomBar/productbar.moreinfo'
import ProductBarAddToCart from './BottomBar/productbar.addtocart'


class VisualizerProductBar extends Component {   

    constructor(props) {
        super(props)
        this.state = {
            areaselect: props.areaselect,
            showmore: true,
            moreinfo: false,
            tab: 1
        }

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.areaselect!==nextProps.areaselect) {
            this.setState({ areaselect: nextProps.areaselect })
        }
    }

    render(){
        const { areaselect, showmore, moreinfo } = this.state
        if(areaselect===null || !this.props.areaproducts[areaselect]) return null;
        const product = this.props.areaproducts[areaselect]

        var style={}
        style['backgroundColor'] = '#'+product.color

        if(product.type==='Texture'){
            style['backgroundImage'] = 'url('+product.image+')'
        }

        return (<div id="productinfobar">
            <div className={"infobox "+(showmore?'more':'less')+" clear"} style={style}>
                <h3 onClick={()=>{ this.setState({showmore:!showmore}) }}>{product.name}</h3>
                {showmore && <ProductBarAddToCart productId={product.id} />}
                {showmore && <Link className="moreinfo" to="#" onClick={(e)=>{ 
                    e.preventDefault(); 
                    this.setState({ moreinfo:!moreinfo})
                    return false; 
                }}>{moreinfo?'Less':'More'}</Link>}                
            </div>

            {showmore && <div className={"moreinfobox"+ (moreinfo?'':' hide')}><ProductbarMoreinfo product={product} /></div>}
        </div>);
    }
}


const mapStateToProps = state => {
    return {
        areaselect: state.area.area_selected,
        areaproducts: state.area.area_products,
    }
}


export default connect(mapStateToProps)(VisualizerProductBar)