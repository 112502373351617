
export const stateAppLoadingInit = () => {
    return {
        type: 'APP_LOADING_INIT',
        loading: true
    };
};

export const stateAppLoadingDone = (data) => {
    return {
        type: 'APP_LOADING_DONE',
        collections: data.collections?data.collections:null,
        products: data.products?data.products:null
    };
};
