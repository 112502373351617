import React from 'react'
import { connect } from 'react-redux'

import './application.css'

import { stateAppLoadingInit, stateAppLoadingDone } from '../../Redux/Actions/Application'
import { loadInitialData } from '../../Model/Application'
import AppNavigation from '../../Navigations/AppNavigation'

class Application extends React.PureComponent {
    state = {
        isAppLoaded: this.props.isAppLoaded,
        isAppLoading: this.props.isAppLoading,
        loadingfailed: false,
        loaded: false
    }

    componentWillReceiveProps(nextProps) {
        //if (this.props.isAppLoaded !== nextProps.isAppLoaded) {
            this.setState({ isAppLoaded: nextProps.isAppLoaded, isAppLoading: nextProps.isAppLoadin })
        //}
    }

    loadAppData() {
        if (this.state.isAppLoading) { return }
        this.props.appDataInit();
        loadInitialData((res) => {
            this.props.appDataDone(res.data);
        }, (res) => {
            this.setState({ loadingfailed: true, isAppLoading: false })
        });
        this.setState({ loadingfailed: false, isAppLoading: true })
    }

    render() {
        if (this.state.loadingfailed) {
            setTimeout(() => { this.loadAppData() }, 10000)
            return (<ApplicationMessage message="Unable to connect server" />)
        }
        if (!this.state.isAppLoaded && !this.state.isAppLoading) {
            setTimeout(() => { this.loadAppData() }, 100)
            return (<ApplicationLoading />);
        }
        if (this.state.isAppLoading) {
            return (<ApplicationLoading />);
        }

        if (this.state.isAppLoaded) {          
            return (<AppNavigation />);
       }
    }
}

export const ApplicationLoading =()=>(
    <div id="apploading"></div>
)

export const ApplicationMessage =({message})=>(
    <div className="loadingmsg">{message}</div>
)


const mapStateToProps = state => {
    return {
        isAppLoaded: state.application.isAppLoaded,
        isAppLoading: state.application.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        appDataInit: () => dispatch(stateAppLoadingInit()),
        appDataDone: (data) => dispatch(stateAppLoadingDone(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Application)