import React, { Component } from 'react'
import { Link } from "react-router-dom";

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.data,
    };
  }

  renderTemplate(item) {
    return (
      <div key={"template"+item.id} className="tempimage">
        <Link to={"/template/"+item.id}> <img src={item.image} alt="" /></Link>
      </div>
    )
  }

  render() {
    const { items } = this.state
    return (
      <div className="template-list">
        {
          items.map(item => (
            this.renderTemplate(item)
          ))
        }
      </div>
    );
  }

}
export default Template;
