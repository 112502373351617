import React, { Component } from 'react'
import { connect } from 'react-redux'


class RightBoxCollections extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collections: props.collections ? props.collections : null,
            products: props.products ? props.products : null,
            searchtext: props.search ? props.search : ''
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.collections === null && nextProps.collections !== null) {
            this.setState({ collections: nextProps.collections, products: nextProps.products })
        }
        if (nextProps.search !== null) {
            this.setState({ searchtext: nextProps.search })
        }
    }

    getCollections(){
        const { collections, products, searchtext } = this.state
        
        var searchval = searchtext.toLowerCase()
        var fproducts = products.filter((product) =>{
            if(searchtext.trim()==='') return true;    
            
            if( product.name.toLowerCase().indexOf(searchval) > -1){
                return (
                    product.name.toLowerCase().indexOf(searchval) > -1);
            }

            if( product.code.toLowerCase().indexOf(searchval) > -1){
                return (
                    product.code.toLowerCase().indexOf(searchval) > -1);
            }

            if( product.color.toLowerCase().indexOf(searchval) > -1){
                return (
                    product.color.toLowerCase().indexOf(searchval) > -1);
            }
          
        });

        var cids=[]
        fproducts.forEach((product, inx) => {
            if(product.cids){
                product.cids.forEach((cid)=>cids.push(cid))
            }
        })

        return collections.filter((collection)=>{
            return (cids.indexOf(collection.id) > -1)
        })
        
    }

    render() {

        var collections = this.getCollections()

        return (<div className="collectionbox">
            <h3>Color Moods</h3>
            <ul className="clear">
                {collections && collections.map((item, i) => {
                    return (<CollectionItem key={'collectionitem' + item.id} item={item} />)
                })}
            </ul>
        </div>);
    }
}

const CollectionItem = ({ item }) => {
    var style = { backgroundColor: '#ffffff' };
    if (item.colors.length > 1) {
    } else {
        style.backgroundColor = '#' + item.colors[0]
    }

    var handleClick=()=>{
        var element = document.getElementById("productbox"+item.id);
        if(element){
            element.scrollIntoView({behavior: "smooth"});
        }
    }

    return (
        <li onClick={handleClick}>
            <span className="color" style={style}></span>
            <span className="title">{item.title}</span>
        </li>
    )
}


const mapStateToProps = state => {

    return {
        collections: state.application.collections,
        products: state.application.products,
        search: state.template.search
    }
}

export default connect(mapStateToProps)(RightBoxCollections)