import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-konva';

class URLImage extends Component {
    state = {
      image: null
    };

    componentDidMount() {
      this.loadImage();
    }

    componentDidUpdate(oldProps) {
      if (oldProps.src !== this.props.src) {
        this.loadImage();
      }
    }

    componentWillUnmount() {
      this.image.removeEventListener('load', this.handleLoad);
    }

    loadImage() {
      this.image = new window.Image();
      this.image.crossOrigin = 'Anonymous';
      this.image.addEventListener('load', this.handleLoad);
      this.image.src = this.props.src;
    }

    handleLoad = () => {
      this.setState({
        image: this.image
      });
      this.props.onLoad(this.imageNode);
      // if you keep same image object during source updates
      // you will have to update layer manually:
      // this.imageNode.getLayer().batchDraw();
    };

    render() {
      if(this.state.image===null) return null;
      return (
        <Image {...this.props} image={this.state.image} ref={node => {
            this.imageNode = node;
        }}/>
      );
    }

}

URLImage.propTypes = {
    onLoad: PropTypes.func
}

URLImage.defaultProps = {
  onLoad: ()=>{},
}

export default URLImage