const defaultState = {
    loading: false,
    collections:null,
    products:null,
    isAppLoaded:false
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'APP_LOADING_INIT':
            return Object.assign({}, state, {
                loading: true
            });
        case 'APP_LOADING_DONE':
            return Object.assign({}, state, {
                loading: false,
                collections: action.collections,
                products: action.products,
                isAppLoaded: true
            });            
        default:
            return state;
    }
}