import React from 'react'
import { connect } from 'react-redux'

import Joyride, { STATUS } from 'react-joyride';

import { stateTemplateSwitchHelp } from '../../Redux/Actions/Template'


function VisualizerHelp(props){
 
  var steps = [
    {
      content: "Let's us help you explore this tool.",
      placement: 'center',
      target: 'body',
    },  
    {
      target: '.infobox',
      content: '1. Select the surface you’d like to color.',
    },
    {
      target: '#rightbox',
      content:"2. Select your desired color from all colors (scroll down) or color theories.",
      //placementBeacon:'top',
      placement :'left',
    },
    {
      target: '.lightonoff',
      content: '3. View in Night Mode or Change Scenery.',
    },
    {
      target: '.menucart',
      content: '4. Add interested colors to cart and send us you requirments for more details.',
    },  
  ];

  const handleJoyrideCallback = (data) => {
    if(!props.help) return;
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];    
    if (finishedStatuses.includes(status)) {      
      props.switchHelp(false);
    }
  };
  
  
  return (
    <div id="page-help">
      <Joyride steps={steps} run={props.help} continuous={true} showSkipButton={true} locale={{last: 'Close'}} 
       scrollToFirstStep={true} showProgress={true} callback={handleJoyrideCallback}  />
    </div>
  )
}


const mapStateToProps = state => {
  return {
      help: state.template.help,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      switchHelp: (state) => dispatch(stateTemplateSwitchHelp(state)),      
  }
}  


export default connect(mapStateToProps,mapDispatchToProps)(VisualizerHelp)