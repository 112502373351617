import React from 'react'
import { Link } from "react-router-dom";

export const PopupHeader=({title, onClose})=>{
    return (
        <div className="popupheader clear">
            <h3 className="popuptitle">{title}</h3>

        <Link className="popupclose" to={loc=>{ return null }} onClick={(e)=>{
            e.stopPropagation()
            e.preventDefault()  
            onClose()          
        }} >Close</Link>
        </div>
    )
}


export const PopupBody=({children})=>{
    return (
        <div className="popupbody">{children}</div>
    )
}