import React, { Component } from 'react'
import { connect } from 'react-redux'


import BottomBarThumBox from './BottomBar/buttombar.thumbox'


class VisualizerBottomBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            template: props.template ? props.template : null,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.template === null && nextProps.template !== null) {
            this.setState({ template: nextProps.template })
        }
    }

    

    render() {

        const { template } = this.state        
        if (template === null) return null;

        const areas = template.template.areas
        const areakeys = Object.keys(areas)

        return (<div id="bottombar">
            <div className="infobox">            
                {areakeys.map((key, index) => {
                    const area = areas[key]
                    return (
                        <BottomBarThumBox key={"areathum_" + key} areakey={key} area={area} />
                    )
                })}
            </div>
        </div>);
    }
}



const mapStateToProps = state => {
    return {
        template: state.template.template,
    }
}


export default connect(mapStateToProps,null)(VisualizerBottomBar)