import React from 'react'
import { Group } from 'react-konva'
import URLTexture from '../../../Ui/Konvajs/URLTexture';





class AreaMapTexture extends React.Component  {    

    constructor(props) {
        super(props);

        this.state = {
            product : null
        }

    }

    changeTexture(product){
        if(this.state.product!==null && product.id===this.state.product.id) return;
        if(product.type==='Solid'){
            if(this.state.product!=null) this.setState({product:null});
            return;
        }
        this.setState({product:product})
    }

    render(){
        const { product } = this.state
        const { area, mapsrc } = this.props

        const textureSize = {
            w: area.wallsize.w * 200,
            h: area.wallsize.h * 200,
        }
        
        return (
            <Group >                    
                {product && <URLTexture src={product.image} mapsrc={mapsrc} x={area.offset.x} y={area.offset.y} size={area.imgsize} txtSize={textureSize} />}
            </Group>
        )
    }
}


export default AreaMapTexture